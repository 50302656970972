/*
  * =============================================================================
  * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
      * RESERVED
  *
  * This software is the confidential and proprietary information of Vrio Corp.
  * ("Proprietary Information"). Any use, reproduction, distribution or
  * disclosure of the software or Proprietary Information, in whole or in part,
  * must comply with the terms of the license agreement, nondisclosure agreement
  * or contract entered into with Vrio Corp. providing access to this software.
  *
  * ============================================================================
  */
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core'
import { AssetService } from '../assets.service'
import { Subject } from 'rxjs'
import { debounceTime, distinctUntilChanged } from 'rxjs/operators'
import { ActivatedRoute } from '@angular/router'
import { ChannelManagementService } from '../../channel-management/channel-management.service'
import { AppConstants } from 'src/app/app.constants'

@Component({
  selector: 'app-assets-list',
  templateUrl: './assets-list.component.html',
  styleUrls: ['./assets-list.component.scss']
})
export class AssetsListComponent implements OnInit {
  @Output() assetId: EventEmitter<string> = new EventEmitter()
  @Input() pageName: string

  readonly CONSTANTS = AppConstants
  searchString: string
  searchChanged: Subject<string> = new Subject<string>()
  assetFilters = [
    { name: 'movies', abbrev: this.CONSTANTS.MOVIE, value: true },
    { name: 'show', abbrev: this.CONSTANTS.SERIES, value: true },
    { name: 'episodes', abbrev: this.CONSTANTS.EPISODE, value: true },
  ]
  additionalFilters = [
    {name: this.CONSTANTS.PUBLISHED, value: true},
    {name: this.CONSTANTS.UNPUBLISHED, value: true}
  ]
  getParams: PaginationParams = {
    page: 1,
    size: 20,
  }
  assets: Assets[]
  selectedAssetId: string
  hasEpisodeSearch: boolean
  assetTypes: ResourceType[]
  selectedType: ResourceType
  isProgramPage: boolean
  showLoader: boolean
  totalAssets: number
  channelsList : ServiceOrChannel []
  serviceCategoryList : any []
  channelOrCategory : string
  getChannelParams: PaginationParams = {
    page: 1,
    size: 100,
  }
  categoryFilter: string[]
  channelFilter: string[]
  listService : any[]
  data = {
    chanType: ["VOD"],
    processingType : '',
    searchString: ''
  }
  channelIds : any

  constructor(
    private assetService: AssetService,
    private route: ActivatedRoute,
    private channelService : ChannelManagementService
  ) { }

  ngOnInit() {
    this.assetTypes = this.assetService
      .assetTypes()
      .filter((service) => service.code !== this.CONSTANTS.EPISODE && service.code !== this.CONSTANTS.SPORT_EPISODE)
    this.selectedType = this.assetTypes[0]
    this.isProgramPage = this.pageName === 'assets'
    this.channelFilter = []
    this.categoryFilter= []
    this.route.params.subscribe((params) => {
      if (params.id) {
        this.searchString = params.id
        this.searchAssets()
      } else {
        this.getAssets()
      }
    })

    this.debouncedSearch()
    this.assets = []
    this.assetService.getServiceCategories().subscribe((data : any) =>{
      this.serviceCategoryList = data.response
    })
    this.channelOrCategory = 'service'
  }
  
  debouncedSearch() {
    this.searchChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((value) => {
        this.searchString = value
        if (!!value) {
          this.getParams.page = 1
          this.searchAssets()
        } else {
          this.getAssets()
        }
      })
  }

  getAssets() {
    const searchData = {
      searchString: this.searchString || '',
      filters: this.assetFilters
        .filter(({ value }) => value)
        .map(({ abbrev }) => abbrev),
      additionalFilters: this.additionalFilters
        .filter(({value}) => value).map(({name}) => name),
      categories: this.categoryFilter,
      channelIds : this.channelFilter
    }
    this.triggerSearchAssets(searchData)
  }

  //set data for additional dropdown
  selectedServiceOrChannel(item: any){
    if(this.channelOrCategory === 'service')
    {
      this.categoryFilter = [`${item}`]
      this.channelFilter = []

    }else{
      this.channelFilter = [`${item}`]
      this.categoryFilter = []
    } 
  }

  //get list of service categories and channel when change additional filter options
  changeAdditionalFilterOption(type) {
    this.channelOrCategory = type
    if (type === 'service') {
      this.assetService.getServiceCategories().subscribe((data : any) =>{
        this.serviceCategoryList = data.response       
      })
    } else if(type === 'channel'){
      this.assetService.getChannels().subscribe((data : any) =>{
        this.channelsList = data.response
      })
    }
  }

  //get program type by programId
  getProgramType(programId : string){
    return programId.substring(0,2);
  }

  //get stype of asset by status
  getAssetTypeStyle(published) {
    return this.assetService.assetTypeLabel(published).style
  }

  //onSearch event
  searchAssets() {
    this.hasEpisodeSearch = false
    if (this.searchString) {
      const regExp = /\d+/g
      const matches = this.searchString.match(regExp)
      this.hasEpisodeSearch = matches && !!matches.length
      const searchData = {
        searchString: this.searchString,
        filters: this.assetFilters
          .filter(({ value }) => value)
          .map(({ abbrev }) => abbrev),
        additionalFilters: this.additionalFilters
        .filter(({value}) => value).map(({name}) => name),
        channelIds : this.channelFilter,
        categories : this.categoryFilter
      }
      this.triggerSearchAssets(searchData)
    }
  }

  triggerSearchAssets(searchData, isScroll = false) {
    if (searchData.searchString.length !== 1) {
      this.showLoader = true
      this.assetService.searchAssets(searchData, this.getParams).subscribe(
        (data: any) => {
          this.showLoader = false
          if (data) {
            if (data.response) {
              const responseAssets = data.response
              this.totalAssets = data.totalElements
              if (isScroll) {
                this.assets.push(...responseAssets)
              } else {
                this.assets = responseAssets

              }
            } else {
              if (this.getParams.page == 1) {
                this.assets = []
              }
            }
          } else {
            this.assets = []
          }
        }
      )
    }
  }

  searchKeyup(event) {
    this.searchChanged.next(event.target.value)
  }

  //set event when change search input
  searchChange(val) {
    this.getParams.page = 1
    if (!val) {
      this.getAssets()
    }
  }

  viewAsset(id) {
    if (this.pageName === 'assets') {
      this.selectedAssetId = id
      this.assetId.emit(id)
      this.setAssetTypesSelect()
    }
  }

  //get type of asset selected
  setAssetTypesSelect() {
    this.assetTypes = this.assetService
      .assetTypes()
      .filter((service) => service.code !== this.CONSTANTS.EPISODE && service.code !== this.CONSTANTS.SPORT_EPISODE)
    if (this.selectedAssetId.substring(0, 2) === this.CONSTANTS.SERIES) {
      this.assetTypes = this.assetService.assetTypes()
    }
  }

  //set event for scrollbar
  onScroll(event) {
    event.preventDefault()
    const elem = event.target
    const limit = elem.scrollHeight - elem.clientHeight
    if (elem.scrollTop > 0 && elem.scrollTop + 1 >= limit) {
      elem.scrollTop -= this.assets.length
      this.getParams.page++
      const searchData = {
        searchString: this.searchString || '',
        filters: this.assetFilters
          .filter(({ value }) => value)
          .map(({ abbrev }) => abbrev),
        additionalFilters: this.additionalFilters
        .filter(({value}) => value).map(({name}) => name),
        channelIds : this.channelFilter,
        categories : this.categoryFilter
      }
      this.triggerSearchAssets(searchData, true)
    }
  }

}
