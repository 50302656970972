import {Component, EventEmitter, Input, OnInit, Output, TemplateRef} from '@angular/core'
import {NgbCalendar, NgbDate, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SchedulesService} from "../../../pages/schedules/schedules.service";
import {
  Offset,
  ScheduleDetails,
  ScheduleEvent,
  ScheduleVersionDetail,
  ScheduleVersionRequest
} from 'src/app/pages/schedules/schedule';
import {ProgramsService} from "../../../pages/programs/programs.service";
import {ConfirmationModalComponent} from "../../confirmation-modal/confirmation-modal.component";
import {SchedulesErrorModalComponent} from "../../../pages/schedules/schedules-error-modal.component";
import {BehaviorSubject} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {OFFSET_OPTIONS, parseDate, getValidQualifiers, getLangFilters, sortByOrders} from "../../../utils/contants";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-schedule-panel',
  templateUrl: './schedule-panel.component.html',
  styleUrls: ['./schedule-panel.component.scss'],
})
export class SchedulesPanelComponent implements OnInit {
  // Accessors
  @Input() shouldHideProgramPanel = true;

  @Input() set selectedScheduleVersions(selected: ScheduleVersionDetail[]) {
    this.selectedScheduleVersionsSubject.next(selected);
    this.scheduleLoadingSubject.next(true);
  };

  @Input() set selectedChannel(selectedChannel: SourceChannel) {
    this.channelSubject.next(selectedChannel);
  }

  @Output() onScheduleVersionRequestChanged: EventEmitter<ScheduleVersionRequest> = new EventEmitter();
  @Output() onShowOrHideProgramPanel: EventEmitter<boolean> = new EventEmitter();
  @Output() onInvalidProgram: EventEmitter<string> = new EventEmitter();

  // public fields
  ratings: any[];
  qualifiers: any[];
  genres: any[];
  channelSubject: BehaviorSubject<SourceChannel> = new BehaviorSubject(null);
  requestSubject: BehaviorSubject<ScheduleVersionRequest> = new BehaviorSubject({});
  schedulesSubject: BehaviorSubject<ScheduleDetails[]> = new BehaviorSubject([]); // will be read from api
  scheduleLoadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedScheduleVersionsSubject: BehaviorSubject<ScheduleVersionDetail[]> = new BehaviorSubject([]);
  private TODAY: NgbDate = this.calendar.getToday();
  private DEFAULT_TIMEZONE = environment.timezone
    ? OFFSET_OPTIONS.find(o => o.value === environment.timezone)
    : OFFSET_OPTIONS[0];
  allEventsChecked = false;
  allEventsIndeterminate = false;
  selectedScheduleEvents: ScheduleEvent[] = []; // user check on check box to select schedules

  // Life cycle methods
  constructor(
    private modalService: NgbModal,
    private calendar: NgbCalendar,
    private programService: ProgramsService,
    private scheduleService: SchedulesService,
  ) {
    this.channelSubject.pipe(distinctUntilChanged())
      .subscribe(channel => {
        const request: ScheduleVersionRequest = {
          channel: channel,
          date: this.requestSubject.getValue()?.date ?? this.TODAY,
        }

        if (channel?.timeZone) {
          request.timezone = OFFSET_OPTIONS.find(o => o.value === channel.timeZone)
        } else {
          request.timezone = this.DEFAULT_TIMEZONE
        }

        this.requestSubject.next(request);
      });
    this.requestSubject.pipe(distinctUntilChanged())
      .subscribe(request => this.onScheduleVersionRequestChanged.emit(request));
  }

  ngOnInit() {
    this.getRatings();
    this.getQualifiers();
    this.getGenres();
  }

  getGenres() {
    this.programService.getAllGenres().subscribe(data => {
      const langFilters = getLangFilters();
      const genreList = []
      data.response?.forEach((genre) => {
        const filter = genre.names;
        sortByOrders(filter, langFilters, 'language');

        if (filter) {
          genreList.push({
            id: genre.id,
            name: filter[0].value,
          })
        }
      })
      this.genres = genreList
    })
  }

  handleSchedulesChanged(schedules: ScheduleDetails[]) {
    this.scheduleLoadingSubject.next(false);
    this.schedulesSubject.next(schedules);
  }

  // Show the qualifiers modal.
  handleMangingQualifiers(qualifierModal: TemplateRef<any>) {
    this.modalService.open(qualifierModal, {
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      keyboard: false,
    });
  }

  // Remove selected events from UI
  handleRemovingEvents() {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modal.componentInstance.title = 'Remove Schedules';
    modal.componentInstance.message = 'Are you sure you want to remove selected schedules?';
    modal.componentInstance.isAlert = false;
    modal.result.then((result) => {
      if (result) {
        this.schedulesSubject.getValue()?.forEach(schedule => {
          schedule.events = schedule.events.filter(event => !this.selectedScheduleEvents.includes(event));
        });
        this.selectedScheduleEvents = [];
      }
    });
  }

  // Show the blackout modal.
  manageBlackout(blackoutModal: TemplateRef<any>) {
    this.modalService.open(blackoutModal, {
      size: 'md',
      ariaLabelledBy: 'modal-basic-title',
      keyboard: false,
    });
  }

  // Close all opening modals on UI.
  closeModal = () => {
    this.allEventsChecked = false;
    this.selectedScheduleEvents = [];
    this.modalService.dismissAll();
  }

  // Removing blackout regions for selected qualifiers on UI to selected schedules.
  applyBlackout = (mobileBlackout: boolean, selectedRegions: string[]) => {
    let blackout;
    let labels;
    if (mobileBlackout) {
      blackout = true;
      labels = [{value: 'mobile_blackout_CL'}];
    } else {
      blackout = !!selectedRegions?.length;
      labels = [];
    }
    this.selectedScheduleEvents.forEach(e => {
      e.blackout = blackout;
      e.blackoutRegions = selectedRegions;
      e.labels = labels;
    });
    this.closeModal();
  }

  // Apply changes for selected qualifiers on UI to selected schedules.
  applyQualifiers = ($event: any[]) => {
    this.selectedScheduleEvents?.forEach(event => {
      event.qualifiers = $event.map(q => 'epgQualifier' + q);
    });
    this.closeModal();
  }

  // Check if there is any event displayed on UI or not
  hasEvent() {
    return !!this.schedulesSubject.getValue()
      ?.find(s => s.hasEvent(this.requestSubject.getValue().timezone, this.requestSubject.getValue().date));
  }

  // Handle stuff when user check all events
  handleCheckingAllEvents($event: boolean) {
    this.allEventsChecked = $event;
    this.allEventsIndeterminate = false;
    if ($event) {
      let displayedEvents: ScheduleEvent[] = [];
      this.schedulesSubject.getValue()?.forEach(s => {
        displayedEvents = displayedEvents
          .concat(s.displayedEvent(this.requestSubject.getValue()?.timezone, this.requestSubject.getValue()?.date));
      });
      this.selectedScheduleEvents = displayedEvents;
    } else {
      this.selectedScheduleEvents = [];
    }
  }

  // Show or hide the program panel on UI
  handleDisplayingProgramPanel($event: boolean) {
    this.onShowOrHideProgramPanel.emit($event);
  }

  // Handle in case there is any event checked on UI
  handleSelectedEventsChanged = (selected: ScheduleEvent[]) => {
    this.selectedScheduleEvents = selected;
    let displayed: ScheduleEvent[] = [];
    this.schedulesSubject.getValue()?.forEach(s => {
      displayed = displayed
        .concat(s.displayedEvent(this.requestSubject.getValue()?.timezone, this.requestSubject.getValue()?.date))
    });
    // Check or uncheck the 'Select All' check box
    this.allEventsChecked = selected && selected.length > 0 ? displayed?.every(e => selected.includes(e)) : false;
    this.allEventsIndeterminate = selected && selected.length > 0 ? !!displayed?.find(e => selected.includes(e)) : false;
  }

  // Get all valid qualifiers from api
  publishSchedules = (): void => {
    if (!this.isValidSchedule()) {
      this.showInvalidScheduleMessages();
      return;
    }
    if (!this.channelSubject?.getValue()?.published) {
      this.warningUnpublishedChannel();
      return;
    }
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    const firstVersion = this.selectedScheduleVersionsSubject.getValue().find(v => v.id === this.schedulesSubject.getValue()[0].id);
    const secondVersion = this.selectedScheduleVersionsSubject.getValue().find(v => v.id === this.schedulesSubject.getValue()[1]?.id);
    const submitMessage = `version ${firstVersion?.version ?? 1} of First schedule and version ${secondVersion?.version ?? 1} of Second schedule`;
    modal.componentInstance.title = 'Submit Changes';
    modal.componentInstance.message = `You are about to submit ${this.selectedScheduleVersionsSubject.getValue().length} versions: ${submitMessage}. Continue?`;
    modal.componentInstance.isAlert = false;
    modal.result.then((result) => {
      if (result) {
        this.schedulesSubject.getValue()?.forEach(s => s.published = true);
        this.saveOrPublish(true);
      }
    });
  }

  saveSchedules = (): void => {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    modal.componentInstance.title = 'Submit Changes';
    modal.componentInstance.message = 'Are you sure you want to continue?';
    modal.componentInstance.isAlert = false;
    modal.result.then((result) => {
      if (result) {
        this.saveOrPublish(false);
      }
    })
  };

  updateScheduleDate = (selectedDate: string): void => {
    const request: ScheduleVersionRequest = {
      ...this.requestSubject.getValue(),
      date: parseDate(selectedDate),
    }
    this.requestSubject.next(request);
  };

  updateTimezone = (selectedOffset: Offset): void => {
    const request: ScheduleVersionRequest = {
      ...this.requestSubject.getValue(),
      timezone: selectedOffset ?? this.requestSubject.getValue()?.timezone,
    }
    this.requestSubject.next(request);
  };

  // Get all valid ratings from api
  private getRatings() {
    const ratingFilter = getValidQualifiers();
    this.programService.getAllRatings().subscribe((data: any) => {
      this.ratings = data.response?.filter(rating => ratingFilter?.includes(rating.code));
    })
  }

  private saveOrPublish(published: boolean): void {
    // VLS-4324: Should not create schedule with empty events when publishing schedule
    let schedules = this.schedulesSubject.getValue();
    if (published == true) {
      schedules = this.schedulesSubject.getValue().filter(schedule => schedule.events && schedule.events.length > 0);
    }

    if (schedules?.length > 0) {
      schedules.forEach(schedule => schedule.provider = 'VLS'); // reset provider to VLS before saving and publishing.
      this.scheduleService.saveAndPublish(schedules, published)
        .subscribe(
          () => {
            this.showAlert('Success', 'Schedules successfully saved');
            this.onScheduleVersionRequestChanged.emit(this.requestSubject.getValue());
          },
          (err: any) => {
            const errMessage = err.error?.errors ? err.error?.errors[0] : 'Submission Failed';
            this.showAlert(`Error`, `${errMessage}`);
          });
    } else {
      this.showAlert(`Warning`, `The published schedules don't have any events. Please add at least one event and try again!`);
    }
  }

  private showAlert(type: string, message: string, size: any = 'sm') {
    const modalRef = this.modalService.open(ConfirmationModalComponent, {
      size,
      centered: true,
      backdrop: 'static',
    })
    modalRef.componentInstance.title = type
    modalRef.componentInstance.message = message
    modalRef.componentInstance.isAlert = true
  }

  private getQualifiers() {
    this.qualifiers = [];
    this.scheduleService.getQualifiers().subscribe(data => {
      this.qualifiers = data.response.map(d => {
        return {
          ...d,
          code: d.name.split('epgQualifier')[1]
        }
      })
    })
  }

  private warningUnpublishedChannel() {
    const modal = this.modalService.open(ConfirmationModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    });
    modal.componentInstance.title = 'Warning';
    modal.componentInstance.message = `The channel ${this.channelSubject?.getValue()?.id} is not published. Please publish this channel first and try again!`;
    modal.componentInstance.isAlert = true;
    modal.result.then((result) => {
      if (result) {
        this.closeModal();
      }
    })
  }

  private showInvalidScheduleMessages() {
    const modal = this.modalService.open(SchedulesErrorModalComponent, {
      size: 'lg',
      centered: true,
      backdrop: 'static',
    })
    let gapEvents: ScheduleEvent[] = [];
    this.schedulesSubject.getValue()?.forEach(s => {
      gapEvents = gapEvents
        .concat(s.events.filter((e, i) => e.isGap(s.events[i + 1])));
    });
    let errorMessages = [];
    if (gapEvents.length > 0) {
      let items = `${gapEvents.map(e => e.formattedStartDate(this.requestSubject.getValue()?.timezone.value)).join(' and ')}`;
      errorMessages.push(`Gap event(s) detected at: ${items} in local timezone`);
    }
    let overlappedEvents = [];
    this.schedulesSubject.getValue()?.forEach(s => {
      overlappedEvents = overlappedEvents
        .concat(s.events.filter((e, i) => e.isOverlapped(s.events[i + 1])));
    });
    if (overlappedEvents.length > 0) {
      let items = `${overlappedEvents.map(e => e.formattedStartDate(this.requestSubject.getValue()?.timezone.value)).join(' and ')}`;
      errorMessages.push(`Overlapped event(s) detected at: ${items} in local timezone`);
    }

    let emptyProgramMessage = null;
    this.schedulesSubject.getValue()?.forEach(s => {
      if (s.events?.length > 0 && s.events.find(e => e.programId == null)) {
        emptyProgramMessage = "Cannot publish an incomplete schedule, please wait for a moment!"
       }
    })
    emptyProgramMessage && errorMessages.push(emptyProgramMessage)

    modal.componentInstance.headerTitle = 'Invalid schedule';
    modal.componentInstance.errorMessages = errorMessages;
    modal.result.then(() => this.closeModal());
  }

  private isValidSchedule(): boolean {
    return this.schedulesSubject.getValue()?.every(s => s.isValid());
  }
}
