import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpHeaders,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { LoginService } from '../pages/login/login.service'

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor(private loginService: LoginService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    
    if (this.loginService.isAuthenticated() && req.url.indexOf('basicauth') === -1) {
      var newHeaders = req.headers.append('x-auth-token', localStorage.getItem('authToken'));
      var authReq = req.clone({ headers: newHeaders });
      return next.handle(authReq)
    } else {
      return next.handle(req)
    }
  }
}
