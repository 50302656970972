/* Angular Libraries */
import {NgModule} from '@angular/core'
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule,} from '@angular/common/http'
import {BrowserModule} from '@angular/platform-browser'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {DragDropModule} from '@angular/cdk/drag-drop'
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import {HttpInterceptorService} from "./services/httpInterceptor.service"
/* Angular Material */
import { MatProgressSpinnerModule} from '@angular/material/progress-spinner'
import { MatFormFieldModule,} from '@angular/material/form-field'
import { MatSelectModule } from '@angular/material/select'
import { MatTabsModule,} from '@angular/material/tabs'
import { MatInputModule} from '@angular/material/input'

/*Chart*/
import { ChartsModule } from 'ng2-charts'

/* 3rd Party libraries */
import {NgbModalModule, NgbModule, NgbNavModule,} from '@ng-bootstrap/ng-bootstrap'
import {NgSelectModule} from '@ng-select/ng-select'
import {TranslateLoader, TranslateModule} from '@ngx-translate/core'
import {TranslateHttpLoader} from '@ngx-translate/http-loader'
import {OwlDateTimeModule, OwlNativeDateTimeModule} from 'ng-pick-datetime'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import { PickListModule } from 'primeng/picklist'
import { MultiSelectModule } from 'primeng/multiselect';

/* Common components */
import {HeaderComponent} from './components/header/header.component'
import {VersionsComponent} from './components/versions/versions.component'
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component'

/* Pages components */
import {LoginComponent} from './pages/login/login.component'

/* Schedules */
import {SchedulesComponent} from './pages/schedules/schedules.component'
import {SchedulesErrorModalComponent} from './pages/schedules/schedules-error-modal.component'
import {SchedulesCopyComponent} from './pages/schedules-copy/schedules-copy.component'

/* Programs */
import {ProgramsComponent} from './pages/programs/programs.component'
import {ProgramListComponent} from './pages/programs/program-list/program-list.component'
import {ProgramFormComponent} from './pages/programs/program-form/program-form.component'

/* Channels */
import {ChannelManagementComponent} from './pages/channel-management/channel-management.component'
import {ChannelModalComponent} from './pages/channel-management/channel-modal/channel-modal.component'
import {ChannelPanelComponent} from './pages/channel-management/channel-panel/channel-panel.component'
import {ChannelsListComponent} from './pages/channel-management/channels-list/channels-list.component'
import {ChannelTransmitComponent} from './pages/channel-transmit/channel-transmit.component'
import {
  TransmitHistoryPanelComponent
} from './pages/channel-transmit/transmit-history-panel/transmit-history-panel.component'

/* Users */
import {UsersComponent} from './pages/users/users.component'
import {UsersRolesComponent} from './pages/users/users-roles.component';
import {ProgramMappingComponent} from './pages/program-mapping/program-mapping.component';
import {TeamManagementComponent} from './pages/world-cup-team/team-management/team-management.component';
import {TeamListComponent} from './pages/world-cup-team/team-list/team-list.component';
import {TeamFormComponent} from './pages/world-cup-team/team-form/team-form.component'

/* Venues */
import {VenuesComponent} from './pages/venues/venues.component'
import {VenueListComponent} from './pages/venues/venue-list/venue-list.component'
import {VenueFormComponent} from './pages/venues/venue-form/venue-form.component'

/* Players */
import {PlayersComponent} from './pages/players/players.component'
import {PlayerListComponent} from './pages/players/player-list/player-list.component'
import {PlayerFormComponent} from './pages/players/player-form/player-form.component'
import {EcadComponent} from './pages/ecad/ecad.component'

/* Report */
import {
  ReportsPublicationScheduleComponent
} from './pages/reports-publication-schedule/reports-publication-schedule.component'
import {ReportsPublicationsComponent} from './pages/reports-publications/reports-publications.component'
import {
  ReportsPublicationsDetailsComponent
} from './pages/reports-publications/reports-publications-details/reports-publications-details.component'
import {
  ChannelMasterSlaveModalComponent
} from './pages/channel-management/channel-master-slave-model/channel-master-slave-model.component'

/* APG Report */
import {APGReportComponent} from './pages/apg-report/apg-report.component';
import {ApgGoReportComponent} from './pages/apg-report/apg-go-report/apg-go-report.component';
import {ApgBioReportComponent} from './pages/apg-report/apg-bio-report/apg-bio-report.component'
import {ApgEpoReportComponent} from './pages/apg-report/apg-epo-report/apg-epo-report.component';
import {ScheduleVersionsComponent} from './components/schedule/schedule-versions/schedule-versions.component'
import {SchedulesBodyComponent} from "./components/schedule/schedule-panel/schedule-body/schedule-body.component";
import {ScheduleEventComponent} from "./components/schedule/schedule-panel/schedule-body/schedule-event/schedule-event.component";
import {SchedulesPanelComponent} from "./components/schedule/schedule-panel/schedule-panel.component";
import {SchedulesHeaderComponent} from "./components/schedule/schedule-panel/schedule-header/schedule-header.component";
import {SchedulesFooterComponent} from "./components/schedule/schedule-panel/schedule-footer/schedule-footer.component";
import {QualifiersModalComponent} from "./components/qualifiers-modal/qualifiers-modal.component";
import {BlackoutModalComponent} from "./components/blackout-modal/blackout-modal.component";
import { UncompletedSchedulesReportComponent } from './pages/schedules-reports/uncompleted-schedules-report/uncompleted-schedules-report.component';

/* VOD Assets */
import { AssetsManagementComponent } from './pages/assets-management/assets-management.component';
import { AssetsListComponent } from './pages/assets-management/assets-list/assets-list.component';
import { AssetsFormComponent } from './pages/assets-management/assets-form/assets-form.component';
import { AssetReportsComponent } from './pages/asset-reports/asset-reports.component';
import { AssetStatsComponent } from './pages/asset-reports/asset-stats/asset-stats.component';
import { AllAssetsComponent } from './pages/asset-reports/all-assets/all-assets.component';
import { SchedulesReportComponent } from './pages/schedules-reports/schedules-report/schedules-report.component';


// AoT requires an exported function for factories
export const httpLoaderFactory = (http: HttpClient) =>
  new TranslateHttpLoader(http)

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    ChannelManagementComponent,
    VersionsComponent,
    ProgramsComponent,
    ProgramListComponent,
    ProgramFormComponent,
    ConfirmationModalComponent,
    QualifiersModalComponent,
    BlackoutModalComponent,
    ChannelPanelComponent,
    ChannelModalComponent,
    ChannelsListComponent,
    ChannelTransmitComponent,
    TransmitHistoryPanelComponent,
    UsersComponent,
    UsersRolesComponent,
    ProgramMappingComponent,
    PlayersComponent,
    PlayerListComponent,
    PlayerFormComponent,
    VenuesComponent,
    VenueListComponent,
    VenueFormComponent,
    TeamManagementComponent,
    TeamListComponent,
    TeamFormComponent,
    EcadComponent,
    ReportsPublicationScheduleComponent,
    ReportsPublicationsComponent,
    ReportsPublicationsDetailsComponent,
    APGReportComponent,
    ChannelMasterSlaveModalComponent,
    ApgGoReportComponent,
    ApgBioReportComponent,
    ApgGoReportComponent,
    ApgEpoReportComponent,
    SchedulesComponent,
    SchedulesErrorModalComponent,
    SchedulesCopyComponent,
    ScheduleVersionsComponent,
    SchedulesPanelComponent,
    SchedulesHeaderComponent,
    SchedulesBodyComponent,
    SchedulesFooterComponent,
    ScheduleEventComponent,
    UncompletedSchedulesReportComponent,
    AssetsManagementComponent,
    AssetsListComponent,
    AssetsFormComponent,
    AssetReportsComponent,
    AssetStatsComponent,
	  AllAssetsComponent,
    SchedulesReportComponent,
  ],
  imports: [
    BrowserModule,
    NgbNavModule,
    HttpClientModule,
    NgbModule,
    NgSelectModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    NgbModalModule,
    DragDropModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSelectModule,
	  MatTabsModule,
    ChartsModule,
    PickListModule,
    MultiSelectModule,
    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ChannelModalComponent,
    HeaderComponent,
    ConfirmationModalComponent,
    SchedulesErrorModalComponent,
  ],
})
export class AppModule {
}
