/*
  * =============================================================================
  * Vrio Corp. PROPRIETARY Copyright© 2021 Vrio Corp. UNPUBLISHED WORK ALL RIGHTS
      * RESERVED
  *
  * This software is the confidential and proprietary information of Vrio Corp.
  * ("Proprietary Information"). Any use, reproduction, distribution or
  * disclosure of the software or Proprietary Information, in whole or in part,
  * must comply with the terms of the license agreement, nondisclosure agreement
  * or contract entered into with Vrio Corp. providing access to this software.
  *
  * ============================================================================
  */
 
import { Injectable } from '@angular/core'
import { HttpClient} from '@angular/common/http'
import { environment } from '../../../environments/environment'
import { AppConstants } from 'src/app/app.constants'

@Injectable({ providedIn: 'root' })
export class AssetService {
  assetUrl: string
  genreUrl: string
  ratingsUrl: string
  teamsUrl: string
  venuesUrl: string
  advisoryUrl: string
  celebrityUrl: string
  usaParentalRatings: string[]
  mpaaRatings: string[]
  brRatings: string[]
  availableTitleLenEn: number[]
  availableTitleLenEs: number[]
  availableDescLenEn: number[]
  availableDescLenEs: number[]
  getParams: {
    page: 0
    size: 300
  }
  assetTest: any
  readonly CONSTANTS = AppConstants

  constructor(private http: HttpClient) {
    const apiUrl = environment.apiUrl
    this.assetUrl = `${apiUrl}/assets`
    this.usaParentalRatings = this.CONSTANTS.PARENTAL_RATING_LIST
    this.mpaaRatings = this.CONSTANTS.MPAA_RATING_LIST
    this.brRatings = this.CONSTANTS.BR_RATING_LIST
    this.assetTest = {}
  }

  searchAssets(searchData, params: PaginationParams) {
    const { page, size } = params
    const searchUrl = `${this.assetUrl}/search?page=${page}&size=${size}`
    return this.http.post<AssetSearch>(searchUrl, searchData)
  }

  getAllAsset(filterType, params: PaginationParams) {
    const { page, size } = params
    const getAllUrl = `${this.assetUrl}/asset-reports?page=${page}&size=${size}`
    return this.http.post<Assets>(getAllUrl, filterType)
  }

  getAssetById(assetId: string) {
    const getAssetUrl = `${this.assetUrl}/asset/${assetId}`
    return this.http.get<Assets>(getAssetUrl)
  }

  getServiceCategories(){
    const getServiceCategoriesUrl =`${this.assetUrl}/category`
    return this.http.get<string[]>(getServiceCategoriesUrl)
  }

    //format status from boolean to string
  formatStatus(contents: any) {
    contents?.forEach(item => {
      if (item.asset.published) {
        item.asset.pubStatus = this.CONSTANTS.PUBLISHED
      }
      else {
        item.asset.pubStatus = this.CONSTANTS.UNPUBLISHED
      }
    })
  }
  
  getChannels(){
    const getChannelsUrl =`${this.assetUrl}/channel`
    return this.http.get<string[]>(getChannelsUrl)
  }
  traverseAndFlatten(currentNode, target, flattenedKey = undefined) {
    for (var key in currentNode) {
      if (currentNode.hasOwnProperty(key)) {
        var newKey
        if (flattenedKey === undefined) {
          newKey = key
        } else {
          if (!isNaN(Number(key.toString()))) {
            newKey = flattenedKey + '[' + key + ']'
          } else {
            newKey = flattenedKey + '.' + key
          }
        }

        var value = currentNode[key]
        if (newKey.includes('extraInfos') && key === 'value') {
          if (value.length > 1 && value[0] !== undefined) {
            target[newKey] = value
          } else {
            target[newKey] = []
          }
        } else if (typeof value === 'object' && !(value instanceof File)) {
          this.traverseAndFlatten(value, target, newKey)
        } else {
          target[newKey] = value
        }
      }
    }
  }

  flatten(obj) {
    var flattenedObject = {}
    this.traverseAndFlatten(obj, flattenedObject)
    return flattenedObject
  }

  save(data) {
    const assetId = data.assetId
    const payload = new FormData()
    this.assetTest = this.flatten(data)
    for (let key in this.assetTest) {
      if (!key.includes('createdDate') && !key.includes('updatedDate')) {
        payload.append(key, this.assetTest[key])
      }
    }

    return !!assetId
      ? this.http.put<Assets>(`${this.assetUrl}/${assetId}`, payload)
      : this.http.post<Assets>(this.assetUrl, payload)
  }

  assetTypes() {
    return [
      { code: this.CONSTANTS.MOVIE, name: this.CONSTANTS.MOVIE_NAME, subType: '' },
      { code: this.CONSTANTS.SHOW, name: this.CONSTANTS.SHOW_NAME, subType: '' },
      { code: this.CONSTANTS.EPISODE, name: this.CONSTANTS.EPISODE_NAME, subType: this.CONSTANTS.EPISODE_SUBTYPE },
      { code: this.CONSTANTS.SPORT_SERIES, name: this.CONSTANTS.SPORT_SERIES_NAME, subType: this.CONSTANTS.SPORT_SERIES_SUBTYPE },
      { code: this.CONSTANTS.SPORT_EPISODE, name: this.CONSTANTS.SPORT_EPISODE_NAME, subType: this.CONSTANTS.SPORT_EPISODE_SUBTYPE },
    ]
  }

  assetTypeLabel(published) {
    let assetType: {
      style: string
    }
    if (published) {
      assetType = {
        style: 'success'
      }
    } else if (!published) {
      assetType = {
        style: 'danger'
      }
    } 
    return assetType
  }

 assetDefault() {
    return {
      _id: '',
    dmsId: '',
    advisories: [],
    assetId: '',
    assetRating: [],
    availabilityEndsAt: '',
    availabilityStartsAt: '',
    programMapperId: '',
    vrioId: '',
    serviceProvider: '',
    serviceCategory: '',
    programId: '',
    assetTitle: '',
    channels: [],
    displaySeasonNumber: '',
    displayEpisodeNumber: '',
    createdDate: '',
    updatedDate: '',
    type: '',
    serviceProviderInfo: {
      serviceProviderCategory: {
        name:'',
        id:'',
        providerId: ''
      },
      serviceProvider:{
        name: '',
        providerId: ''
      }
    },
    published: ''
  }
}

assetUrlDefault(){
  return {
    drmInfo:{
      drm: '',
      vendorId: '',
      vendorName: '',
      drmId: '',
      certificateUrl: '',
      licenseUrl: ''
  },
  cdnInfo:{
      vendorId: '',
      vendorName: '',
      country: '',
      baseUrl: '',
      channelId:'',
  }
  }
}
channelOrServiceDefault() {
  return [{
    type: 'service',
    name: '',
    id: ''
    }]
  }
}